// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-help-articles-how-to-add-video-in-html-js": () => import("./../../../src/pages/help/articles/how-to-add-video-in-html.js" /* webpackChunkName: "component---src-pages-help-articles-how-to-add-video-in-html-js" */),
  "component---src-pages-help-articles-how-to-add-video-in-squarespace-js": () => import("./../../../src/pages/help/articles/how-to-add-video-in-squarespace.js" /* webpackChunkName: "component---src-pages-help-articles-how-to-add-video-in-squarespace-js" */),
  "component---src-pages-help-articles-how-to-add-video-in-wix-js": () => import("./../../../src/pages/help/articles/how-to-add-video-in-wix.js" /* webpackChunkName: "component---src-pages-help-articles-how-to-add-video-in-wix-js" */),
  "component---src-pages-help-articles-how-to-add-video-in-wordpress-elementor-js": () => import("./../../../src/pages/help/articles/how-to-add-video-in-wordpress-elementor.js" /* webpackChunkName: "component---src-pages-help-articles-how-to-add-video-in-wordpress-elementor-js" */),
  "component---src-pages-help-articles-how-to-upload-video-js": () => import("./../../../src/pages/help/articles/how-to-upload-video.js" /* webpackChunkName: "component---src-pages-help-articles-how-to-upload-video-js" */),
  "component---src-pages-help-contact-js": () => import("./../../../src/pages/help/contact.js" /* webpackChunkName: "component---src-pages-help-contact-js" */),
  "component---src-pages-help-faq-js": () => import("./../../../src/pages/help/faq.js" /* webpackChunkName: "component---src-pages-help-faq-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

